// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/bg_cedears.jpg";
import IframeVIdeo from "../../components/iframe_video/iframe_video";
import "./cedears.css";
import faqjson from "../../misc/cedears_related/faq.json";
import slick_items from "../../misc/cedears_related/slick_items_cedears.json";
import { SalWrapper } from "react-sal";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, Link, useStaticQuery } from "gatsby";


const pageTitle = 'Invertí en CEDEAR desde la mejor App de inversiones | Balanz';
const pageDesc = 'Descubrí cómo comprar CEDEARs e invertir en las principales empresas del mundo desde Argentina. Abrí tu cuenta hoy y empezá a invertir en CEDEARs.';
let text = `Creemos que existen tantas inversiones como inversores, <span class='highlightsbold'> te presentamos CEDEARs.</span>`;

const CedearsPage = () => {
    const [xs, setXs] = useState(false);

    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 5}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: false,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          /*{
              breakpoint: 1200,
              settings: { slidesToShow: 5, slidesToScroll: 5, infinite: false }
          },*/
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };
    useEffect(() => {
        apiRequest
            .get("/v1/faq/5")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })

                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
  const page = (
    <>
        <Helmet>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Qué son los CEDEARs?",
                        "description": "Un CEDEAR es un Certificado de Depósito Argentino que representa un activo del exterior que cotiza en Argentina a través de un certificado.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=IidfGtULiKs",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/cedears/",
                        "embedUrl": "https://balanz.com/inversiones/cedears/"
                    }
                `}
            </script>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Cómo invertir en CEDEARs?",
                        "description": "Es muy fácil, lo hacés directamente desde nuestra plataforma.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=w3Ldre7Metc",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/cedears/",
                        "embedUrl": "https://balanz.com/inversiones/cedears/"
                    }
                `}
            </script>
        </Helmet>

        <ProductBanner
          bannerContainerClassName="bannerCedear"
          bannerTrasparecy="bannerMask"
          productTitle="CEDEARs"
          bannerImage={Banner}
          bannerImageClassName="cedearsBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
        <SalWrapper>
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2
                        data-sal="fade"
                        data-sal-delay="500"
                        data-sal-duration="400"
                    >¿Qué son los CEDEARs?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Un CEDEAR es un <strong>Certificado de Depósito Argentino</strong> que representa un activo del exterior que cotiza en Argentina a través de un certificado. Los activos pueden ser Acciones o ETFs. La cotización del CEDEAR en pesos está impactada por el valor del activo subyacente (Acción o ETF), el ratio de conversión y el tipo de cambio implícito.
                            </p>
                            <div className="pt-4 text-center"
                                 data-sal="fade"
                                 data-sal-delay="300"
                                 data-sal-duration="300"
                            >
                                <Button id="cedears_invertir_1" variant="secondary" text="Invertir en CEDEARs" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container
                data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="200"
            >
                <Row className="py-5 justify-content-center">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/embed/IidfGtULiKs"
                            resolutionClass={null}
                        />
                    </Col>
                </Row>
                <Row className="gx-5 gy-4 pt-3 pt-sm-2 pt-md-3 pt-md-4 pt-lg-5">
                    <Col xs={12}>
                        <p className="product-text">
                          Es la posibilidad de comprar desde tu cuenta local en
                          Argentina, acciones de más de 250 de las mejores
                          compañías que cotizan en la Bolsa Americana como Apple, Tesla, Coca Cola, Amazon, Google o Nvidia.
                            Algunas compañías pagan dividendos a sus accionistas, en
                          ese caso los CEDEARs ofrecen la oportunidad de tener una
                          renta en dólares.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <div className="text-center pt-4 p-lg-3"
                         data-sal="fade"
                         data-sal-delay="300"
                         data-sal-duration="300"
                    >

                        <Button variant="secondary" text="Ver CEDEARS" onClick={() => { window.open("http://cms.balanz.com/PFS/067646_bymatablacedears20220118.pdf ", "_blank") }}/>

                    </div>
                </Row>
            </Container>
        </section>

        <section className="fullwidthbg cedears-de-etf py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">¿Querés conocer más sobre CEDEARs de ETFs?</span></h3>
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >
                            <Link to="/inversiones/cedears/cedear-de-etf/">
                                <Button variant="secondary" text="Ver más"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>

        <section className="fullwidth razones">
             <Container>
                 <Row>
                    <h2 className="mt-2"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="400"
                    >5 razones para invertir en CEDEARS</h2>

                  <SlickProduct
                    slickSettings={slickSettings}
                    list={slick_items}
                  />
                </Row>
             </Container>
         </section>
        <section className="fullwidth como-operar"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en CEDEARs?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                          Es muy fácil, lo hacés directamente desde nuestra
                          plataforma. <br/>Te recomendamos conocer tu perfil de
                          inversor y analizar junto con tu asesor, si es necesario,
                          cuál es la mejor opción para vos.</p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button id="cedears_invertir_2" variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir en CEDEARs" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                      </div>

                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                    <IframeVIdeo
                        src="https://www.youtube.com/embed/w3Ldre7Metc"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
                 {/*<Row className="d-block d-md-none">
                     <div className="text-center pt-4 p-lg-3">
                         <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                     </div>
                 </Row>*/}
             </Container>
        </section>
        <section className="fullwidthbg nuestrospacks py-4 py-md-5">
            <Container className="d-flex align-items-center">
                  <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5">
                    <Col xs={12} md={6}>
                        <div className="section-content banner2-Title"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">También podés invertir en CEDEARS a través de <span className="highlightsbold">nuestros Packs </span></span></h3>
                        </div>
                        <div className="d-none d-md-block cta mt-5 mb-2"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >
                            <Button id="cedears_comprar_pack" variant="white secondary" text="Conocé más" onClick={() => { window.open("/inversiones/cedears/packs-de-cedears/", "_blank") }} />
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="section-content"
                             data-sal="slide-up"
                             data-sal-delay="100"
                             data-sal-duration="200"
                        >
                            <div className="texto banner2-text me-3 me-md-3 me-lg-4 me-xl-5">
                                Son portfolios armados por nuestros especialistas en inversiones.
                                <br />
                                Contamos con Packs de diversas
                                <br /> temáticas: 5G, Inteligencia artificial,
                                <br /> Commodities, Acciones Value,
                                <br /> Vehículos Autónomos y muchos más.
                                <br /> Consultá nuestros CEDEARs recomendados.
                            </div>
                            <div className="d-block d-md-none cta mt-5 mb-2"
                                 data-sal="fade"
                                 data-sal-delay="300"
                                 data-sal-duration="200"
                            >
                                <Button variant="white secondary" text="Comprar Pack" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </div>

                    </Col>
                  </Row>
            </Container>
        </section>
        <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5 mt-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en CEDEARs? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>
        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'cedears-faq'} />
                </Row>
              </Container>
        </section>
        </SalWrapper>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"cedears"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default CedearsPage;
